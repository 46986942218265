import { useCallback, useRef, useEffect, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IColumn, Notification, Table } from 'react-ui-kit-exante';

import { useUpdateGroupSettingsMutation } from '~/api';
import { useTableVirtualized } from '~/hooks';
import { getTableId, TableId } from '~/shared/utils/table/tableId';
import {
  selectEntity,
  selectUserSelectedGroup,
  resetGroupIds,
  selectIsLoading,
} from '~/store/treeStructure';
import { RowType } from '~/types/common';
import {
  TabAccount,
  TabGroup,
  UseHandleCellClick,
} from '~/types/treeStructure';

import { PanelHeaderControls } from '../PanelHeaderControls';
import { WrapperLoader } from '../WrapperLoader';

import {
  StyledHeader,
  StyledControls,
  StyledTableWrapper,
} from './TreeStructure.styled';
import { Tabs, Search } from './components';
import { canExpandDefinition } from './helpers';

interface ITreeStructure<TTree extends { rowType: RowType }> {
  columns: IColumn<TTree>[];
  expandedRows: boolean | Record<string, boolean>;
  handleCellClick: (params: UseHandleCellClick) => Promise<void>;
  hasChangedData: boolean;
  isLoading?: boolean;
  onFilterChange: () => void;
  onRefresh: () => void;
  onSave: () => Promise<void>;
  onSearch: (value: string) => void;
  tabAccount: TabAccount;
  tabGroup: TabGroup;
  tableData: TTree[];
  title: keyof typeof TableId;
}

export const TreeStructure = <TTree extends { rowType: RowType }>({
  columns,
  expandedRows,
  handleCellClick,
  hasChangedData,
  isLoading = false,
  onFilterChange,
  onRefresh,
  onSave,
  onSearch,
  tabAccount,
  tabGroup,
  tableData,
  title,
}: ITreeStructure<TTree>) => {
  const expanded = {
    canExpandDefinition,
    customExpandControl: true,
    listOfInitialExpandedRowKeys: expandedRows,
  };

  const tableRef = useRef<HTMLDivElement | null>(null);
  const { virtualized } = useTableVirtualized(tableRef.current);

  const dispatch = useDispatch();
  const accountId = useSelector(selectEntity);
  const userSelectedGroupForAccount = useSelector(selectUserSelectedGroup);
  const isStoreLoading = useSelector(selectIsLoading);

  const [updateGroupSettings] = useUpdateGroupSettingsMutation();

  const disableSaveButton = !hasChangedData && !userSelectedGroupForAccount;

  const onSaveHandler = useCallback(async () => {
    if (userSelectedGroupForAccount && typeof accountId === 'string') {
      const response = await updateGroupSettings({
        accountId,
        data: {
          limitsSetId: userSelectedGroupForAccount,
        },
      });

      if ('data' in response) {
        dispatch(resetGroupIds(userSelectedGroupForAccount));

        Notification.success({
          title: 'Group for account successfully updated',
        });
      }
    }

    if (hasChangedData) {
      await onSave();
    }
  }, [
    accountId,
    dispatch,
    hasChangedData,
    onSave,
    updateGroupSettings,
    userSelectedGroupForAccount,
  ]);

  const onClickHandler = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isStoreLoading) {
        Notification.warning('Tree is loading, please wait');
        event.stopPropagation();
      }
    },
    [isStoreLoading],
  );

  // set default group id
  useEffect(() => {
    tabGroup.getOptions();
  }, []);

  return (
    <section onClickCapture={onClickHandler}>
      <StyledHeader>{title}</StyledHeader>

      <WrapperLoader isLoading={isLoading} opacity={0.7}>
        <Tabs
          onFilterChange={onFilterChange}
          tabAccount={tabAccount}
          tabGroup={tabGroup}
        />

        <StyledControls>
          <Search onSearch={onSearch} />

          <PanelHeaderControls
            disableSaveButton={disableSaveButton}
            onRefresh={onRefresh}
            onSave={onSaveHandler}
          />
        </StyledControls>

        <StyledTableWrapper ref={tableRef}>
          <Table
            columns={columns}
            data={tableData}
            defaultSortBy={[]}
            expanded={expanded}
            handleCellClick={handleCellClick}
            isFlexLayout
            saveViewParamsAfterLeave
            tableId={getTableId(title)}
            virtualized={virtualized}
            isPinnedHeader
          />
        </StyledTableWrapper>
      </WrapperLoader>
    </section>
  );
};
