import { formatCurrency, defaultLocaleFormatter } from '~/shared/utils';
import { IPosition } from '~/types/accounts';

export const getAdditionalColumns = (
  currency: string,
  displayLock: boolean,
) => {
  if (!displayLock) {
    return [[], []];
  }

  return [
    [
      {
        Header: 'Locked Value',
        formatting: 'number',
        accessor: (row: IPosition) => row?.blockedQtty,
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
      },
      {
        Header: `Converted Locked Value, ${currency}`,
        formatting: 'number',
        width: 185,
        accessor: (row: IPosition) => row?.convertedLockedValue,
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
      },
    ],
    [
      {
        Header: 'Locked Quantity',
        accessor: (row: IPosition) =>
          row?.lock?.reduce((acc, { amount }) => acc + amount, 0),
        Cell: ({ value }: IPosition) =>
          formatCurrency(defaultLocaleFormatter, value),
        formatting: 'number',
      },
    ],
  ];
};
