import { map } from 'lodash';
import { createElement } from 'react';
import { ICellValue } from 'react-ui-kit-exante';

import { TooltipWrapper } from '~/shared/components/TooltipWrapper';
import { getTableId } from '~/shared/utils';
import { ITransaction, TTransactionColumns } from '~/types/transactions';

export const TABLE_ID = getTableId('Transactions');

export const DEFAULT_TABLE_ENTITY = 'transactions-list';

export const TRANSACTION_COLUMNS_ALL: TTransactionColumns[] = map(
  [
    'exanteCounterparty',
    'id',
    'uuid',
    'parentUuid',
    'accountId',
    'timestamp',
    'operationType',
    'asset',
    'sum',
    'rawSum',
    'convertedSum',
    'who',
    'injectedBy',
    'comment',
    'internalComment',
    'symbolId',
    'isin',
    'symbolType',
    'symbolTypeOverride',
    'valueDate',
    'orderId',
    'orderPos',
    'price',
    'clientType',
    'executionCounterparty',
    'exchangeCommission',
    'category',
    'baseCurrency',
    'settlementCurrency',
    'settlementCurrencyMovement',
    'settlementCounterparty',
    'counterpartyAccountId',
    'transferId',
    'internalCounterparty',
    'clientCounterparty',
    'clientCountry',
    'legalEntity',
    'extraData',
    'chainId',
    'isRollback',
    'isRolledback',
    'pnl',
    'blockedQtty',
    'sequenceId',
  ],
  (id) => ({ id }),
);

export const TRANSACTION_COLUMNS_PROPS: Record<
  string,
  Record<string, unknown>
> = {
  id: { Header: 'Id', accessor: 'id', disableFilters: true },
  uuid: {
    Header: 'Uuid',
    accessor: 'uuid',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  parentUuid: {
    Header: 'Parent uuid',
    accessor: 'parentUuid',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  accountId: {
    Header: 'Account id',
    accessor: 'accountId',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  timestamp: {
    Header: 'Timestamp',
    accessor: 'timestamp',
    disableFilters: true,
    formatting: 'dateTimeUTC',
    sortDescFirst: true,
  },
  operationType: {
    Header: 'Operation type',
    accessor: 'operationType',
    disableSortBy: true,
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'multiSelect',
  },
  asset: {
    Header: 'Asset',
    accessor: 'asset',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  sum: {
    Header: 'Sum',
    accessor: 'sum',
    disableFilters: true,
    disableSortBy: true,
    formatting: 'number',
  },
  convertedSum: {
    Header: 'Sum in EUR',
    accessor: 'convertedSum',
    disableFilters: true,
    disableSortBy: true,
    formatting: 'number',
  },
  who: {
    Header: 'Who',
    accessor: 'who',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  comment: {
    Header: 'Comment',
    accessor: 'comment',
    disableFilters: true,
    disableSortBy: true,
    editParams: {
      editable: true,
    },
  },
  internalComment: {
    Header: 'Internal comment',
    accessor: 'internalComment',
    disableFilters: true,
    disableSortBy: true,
    editParams: {
      editable: true,
    },
  },
  symbolId: {
    Header: 'Symbol',
    accessor: 'symbolId',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  symbolType: {
    Header: 'Symbol type',
    accessor: 'symbolType',
    disableFilters: true,
    disableSortBy: true,
  },
  valueDate: {
    Header: 'Value date',
    accessor: 'valueDate',
    disableFilters: true,
    disableSortBy: true,
    formatting: 'dateUTC',
    type: 'dateRange',
  },
  orderId: {
    Header: 'Order id',
    accessor: 'orderId',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  orderPos: {
    Header: 'Order position',
    accessor: 'orderPos',
    disableSortBy: true,
    onFilter: true,
    onRemove: true,
  },
  price: {
    Header: 'Price',
    accessor: 'price',
    disableFilters: true,
    disableSortBy: true,
    formatting: 'number',
  },
  clientType: {
    Header: 'Client type',
    accessor: 'clientType',
    disableFilters: true,
    disableSortBy: true,
  },
  executionCounterparty: {
    Header: 'Counterparty',
    accessor: 'executionCounterparty',
    disableFilters: true,
    disableSortBy: true,
  },
  exchangeCommission: {
    Header: 'Exchange commission',
    accessor: 'exchangeCommission',
    disableFilters: true,
    disableSortBy: true,
    formatting: 'number',
  },
  category: {
    Header: 'Category',
    accessor: 'category',
    disableSortBy: true,
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'multiSelect',
  },
  baseCurrency: {
    Header: 'Base currency',
    accessor: 'baseCurrency',
    disableFilters: true,
    disableSortBy: true,
  },
  settlementCurrency: {
    Header: 'Settlement Currency',
    accessor: 'settlementCurrency',
    disableFilters: true,
    disableSortBy: true,
  },
  settlementCurrencyMovement: {
    Header: 'Settlement currency movement',
    accessor: 'settlementCurrencyMovement',
    disableFilters: true,
    disableSortBy: true,
    formatting: 'number',
  },
  settlementCounterparty: {
    Header: 'Settlement counterparty',
    accessor: 'settlementCounterparty',
    disableFilters: true,
    disableSortBy: true,
  },
  transferId: {
    Header: 'Transfer ID',
    accessor: 'transferId',
    disableFilters: true,
    disableSortBy: true,
  },
  internalCounterparty: {
    Header: 'Internal counterparty',
    accessor: 'internalCounterparty',
    disableFilters: true,
    disableSortBy: true,
  },
  clientCounterparty: {
    Header: 'Client counterparty',
    accessor: 'clientCounterparty',
    disableFilters: true,
    disableSortBy: true,
  },
  legalEntity: {
    Header: 'Legal entity',
    accessor: 'legalEntity',
    disableSortBy: true,
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'multiSelect',
  },
  extraData: {
    Header: 'Extra Data',
    accessor: 'extraData',
    disableFilters: true,
    disableSortBy: true,
    tooltip: {
      type: 'custom',
      options: {
        Title(value: unknown) {
          return createElement(TooltipWrapper, { value });
        },
      },
    },
  },
  blockedQtty: {
    Header: 'Locked Quantity',
    accessor: 'blockedQtty',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { values } }: ICellValue<ITransaction>) => {
      const blockedQtty = values?.blockedQtty;

      return blockedQtty ? Number(blockedQtty).toLocaleString() : '–';
    },
  },
};
